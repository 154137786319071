import { FC } from "react";
import { IonButton, IonFooter, IonIcon } from "@ionic/react";
import { fingerPrintOutline, readerOutline } from "ionicons/icons";

const Footer: FC = () => {
  return (
    <IonFooter class="ion-text-center">
      <IonButton
        fill="clear"
        size="small"
        routerLink="/Imprint"
        routerDirection="none"
      >
        <IonIcon slot="start" icon={fingerPrintOutline}></IonIcon>
        Impressum
      </IonButton>
      <IonButton
        fill="clear"
        size="small"
        routerLink="/Privacy"
        routerDirection="none"
      >
        <IonIcon slot="start" icon={readerOutline}></IonIcon>
        Datenschutz
      </IonButton>
    </IonFooter>
  );
};

export default Footer;
