import "./Reserve.css";
import { FC } from "react";
import {IonContent, IonPage, IonText} from "@ionic/react";
import Toolbar from "../components/Toolbar";

const Reserve: FC = () => {return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-text-center">
          <IonText>
              <p>Page under construction</p>
          </IonText>
      </IonContent>
    </IonPage>
  );
};

export default Reserve;
