import { FC } from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import Toolbar from "../components/Toolbar";
import "./Home.css";
import {
  footballOutline,
  footballSharp,
  pawOutline,
  pawSharp,
  roseOutline,
  roseSharp,
  todayOutline,
  todaySharp,
  trophyOutline,
  trophySharp,
  wifiOutline,
  wifiSharp,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import ServiceCard from "../components/Card/ServiceCard";

const Home: FC = () => {
  const { t } = useTranslation("Home");

  return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-text-center">
        <IonAccordionGroup>
          <IonAccordion value="first">
            <IonItem slot="header" color="light">
              <IonLabel className="ion-text-end">
                <IonText>{t("restaurant.title", "The Restaurant")}</IonText>
              </IonLabel>
            </IonItem>
            <div slot="content">
              <IonText>
                <p className="description">
                  {t(
                    "restaurant.description",
                    "Since 1977, Osteria Uno has been a popular meeting place for people who want to celebrate everyday life.\nIn our atmospherically designed rooms, we offer high-quality Italian dishes such as homemade lasagna, tortelloni, or ravioli.\nOur menu is supplemented with freshly prepared dishes for both lunch and dinner.\nAt Osteria Uno, you experience the multicultural tolerance of the capital city combined with the Dolce Vita of Italy."
                  )}
                </p>
              </IonText>
            </div>
          </IonAccordion>
          <picture>
            <source srcSet="/assets/videos/tv.webp" type="image/webp" />
            <source
              srcSet="/assets/images/restaurant-outside-menu.jpg"
              type="image/png"
            />
            <img
              src="/assets/images/restaurant-outside-menu.jpg"
              alt="Restaurant outside menu"
            />
          </picture>

          <div className="card-container">
            <ServiceCard
              icons={{ iosIcon: wifiOutline, mdIcon: wifiSharp }}
              title={t("cards.freeWiFi.title", "Free WiFi")}
              description={t(
                "cards.freeWiFi.description",
                "Free wifi is available for our customers, please ask our service for the access details."
              )}
            />
            <ServiceCard
              icons={{ iosIcon: footballOutline, mdIcon: footballSharp }}
              title={t("cards.freeKidsArea.title", "Free Kids Area")}
              description={t(
                "cards.freeKidsArea.description",
                "Our open garden features a free kids' area with toys for children to play and have fun together."
              )}
            />
            <ServiceCard
              icons={{ iosIcon: pawOutline, mdIcon: pawSharp }}
              title={t("cards.petFriendly.title", "Pet Friendly")}
              description={t(
                "cards.petFriendly.description",
                "We welcome pets at our restaurant, so feel free to bring your furry friend along."
              )}
            />
          </div>
          <IonAccordion value="second">
            <IonItem slot="header" color="light">
              <IonLabel className="ion-text-end">
                {t("garden.title", "The Garden")}
              </IonLabel>
            </IonItem>
            <div slot="content">
              <p className="description">
                {t(
                  "garden.description",
                  "Our courtyard garden offers a Mediterranean setting where you can experience romantic evenings late into the night.\n With our children's area and a fountain, it also provides an ideal setting for families and groups for unforgettable celebrations, such as weddings.\nEven in bad weather, you don't have to leave the garden - we offer a covered area."
                )}
              </p>
            </div>
          </IonAccordion>
          <picture>
            <source srcSet="/assets/videos/garden.webp" type="image/webp" />
            <source
              srcSet="/assets/images/garden-outside.jpg"
              type="image/png"
            />
            <img src="/assets/images/garden-outside.jpg" alt="Garden outside" />
          </picture>
          <div className="card-container">
            <ServiceCard
              icons={{ iosIcon: todayOutline, mdIcon: todaySharp }}
              title={t("cards.openSeven.title", "Open 7/7")}
              description={t(
                "cards.openSeven.description",
                "Osteria N.1 is open every day of the week, serving delicious Italian cuisine to its customers."
              )}
            />
            <ServiceCard
              icons={{ iosIcon: trophyOutline, mdIcon: trophySharp }}
              title={t(
                "cards.grandEventsWelcomed.title",
                "Grand Events Welcomed"
              )}
              description={t(
                "cards.grandEventsWelcomed.description",
                "Host your next big event by us, where our spacious venue and expert team will make it unforgettable."
              )}
            />
            <ServiceCard
              icons={{ iosIcon: roseOutline, mdIcon: roseSharp }}
              title={t("cards.freshAndFlavorful.title", "Fresh & Flavorful")}
              description={t(
                "cards.freshAndFlavorful.description",
                "Osteria N.1 offers a variety of delicious vegetarian dishes on its menu."
              )}
            />
          </div>
        </IonAccordionGroup>
      </IonContent>
    </IonPage>
  );
};

export default Home;
