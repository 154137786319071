import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { detectBrowserLanguage } from "./services/browser";

const userLanguage = detectBrowserLanguage();

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: userLanguage,
    ns: ["Home", "Reserve", "Contact", "Menu", "Partners", "About"],
    fallbackLng: "en",
    debug: true,
  });
