import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./theme/variables.css";
import { FC, Suspense } from "react";
import Home from "./pages/Home";
import Reserve from "./pages/Reserve";
import MenuPage from "./pages/Menu";
import Contact from "./pages/Contact";
import Location from "./pages/Location";
import Partners from "./pages/Partners";
import About from "./pages/About";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";
import Loading from "./pages/Loading";

setupIonicReact({
  innerHTMLTemplatesEnabled: true,
});

const App: FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/" exact={true}>
                <Home />
              </Route>
              <Route path="/Home">
                <Home />
              </Route>
              <Route path="/Reserve">
                <Reserve />
              </Route>
              <Route path="/Menu">
                <MenuPage />
              </Route>
              <Route path="/Contact">
                <Contact />
              </Route>
              <Route path="/Location" exact={true}>
                <Location />
              </Route>
              <Route path="/Partners" exact={true}>
                <Partners />
              </Route>
              <Route path="/About" exact={true}>
                <About />
              </Route>
              <Route path="/Imprint" exact={true}>
                <Imprint />
              </Route>
              <Route path="/Privacy" exact={true}>
                <Privacy />
              </Route>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </Suspense>
  );
};

export default App;
