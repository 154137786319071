import { FC } from "react";
import { IonContent, IonPage, IonText } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "./About.css";
import Toolbar from "../components/Toolbar";
import { Autoplay } from "swiper";
import { useTranslation } from "react-i18next";

const About: FC = () => {
  const { t } = useTranslation("About");

  return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-text-center ion-padding">
        <IonText>
          <h1 className="about-header">
            {t(
              "header",
              "Welcome to Osteria N.1, best Italian restaurant in Berlin - Kreuzberg"
            )}
          </h1>
          <p className="about-paragraph">
            {t(
              "paragraph_1",
              "The best Italian restaurant in Berlin, located in the heart of Kreuzberg. Our cozy and charming venue has been serving authentic Italian cuisine for over 40 years, earning a reputation as the go-to place for the best pizza in Berlin."
            )}
          </p>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={2}
            loop={true}
            autoplay={{ delay: 3000 }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide>
              <img
                src="/assets/images/gallery/garden-top.png"
                alt="Osteria N.1 Garden"
                className="carousel-image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/assets/images/gallery/prosecco.png"
                alt="Prosecco in ice"
                className="carousel-image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/assets/images/gallery/candle.png"
                alt="Candle on table"
                className="carousel-image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/assets/images/gallery/menu.png"
                alt="Daily Menu at the entrance"
                className="carousel-image"
              />
            </SwiperSlide>
          </Swiper>

          <p className="about-paragraph">
            {t("paragraph_2.part_1", "As featured in the ")}
            <a
              href="https://www.tagesspiegel.de/berlin/von-tisch-zu-tisch-osteria-numero-uno-in-kreuzberg-795913.html"
              className="about-link"
            >
              {" Tagesspiegel "}
            </a>
            {t("paragraph_2.part_2", "and")}
            <a
              href="https://www.bz-berlin.de/ich-und-berlin/bz-gastro/osteria-numero-uno-retro-italiener"
              className="about-link"
            >
              {" BZ "}
            </a>
            {t(
              "paragraph_2.part_3",
              ", Osteria Uno prides itself on offering high-quality Italian food and hospitality. Our menu, crafted by Chef Domenico and his team, focuses on homemade delights, including fresh pasta, salads, grilled meats, and fish, as well as delicious desserts. Our signature stone oven pizzas have become a local favorite."
            )}
          </p>
          <p className="about-paragraph">
            {t(
              "paragraph_3",
              "At Osteria N.1, we believe that the Italian way of life is best enjoyed through mouthwatering dishes shared with friends and family. Our warm and inviting atmosphere is perfect for a quick espresso at the bar or a leisurely meal in our picturesque courtyard garden during the summer months."
            )}
          </p>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={2}
            loop={true}
            autoplay={{ delay: 3000 }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide>
              <img
                src="/assets/images/gallery/front-restaurant.png"
                alt="1"
                className="carousel-image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/assets/images/gallery/garden-1.png"
                alt="2"
                className="carousel-image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/assets/images/gallery/garden-2.png"
                alt="3"
                className="carousel-image"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/assets/images/gallery/garden-3.png"
                alt="4"
                className="carousel-image"
              />
            </SwiperSlide>
          </Swiper>
          <p className="about-paragraph">
            {t(
              "paragraph_4",
              "Whether you're looking for where to eat in Berlin for a quick lunch or a night out after the theater, Osteria N.1 has you covered. Our family-friendly establishment also offers a kids' playground and ice cream in the summer, making it the ideal destination for a relaxing Sunday afternoon."
            )}
          </p>
          <p className="about-paragraph">
            {t(
              "paragraph_5",
              "If you're planning an event, our restaurant can accommodate celebrations of all kinds, from birthdays and weddings to corporate gatherings. Join us at Osteria N.1 and experience the best Italian food in Kreuzberg, Berlin."
            )}
          </p>
        </IonText>
      </IonContent>
    </IonPage>
  );
};

export default About;
