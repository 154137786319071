import { FC } from "react";
import { IonContent, IonPage } from "@ionic/react";
import Toolbar from "../components/Toolbar";

const Privacy: FC = () => {
  return (
    <IonPage>
      <Toolbar />
      <IonContent>
        <p>Datenschutzerkl&auml;rung</p>

        <p>1. Datenschutz auf einen Blick</p>

        <p> Allgemeine Hinweise </p>

        <p>
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick
          dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen.{" "}
        </p>

        <p>
          Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich
          identifiziert werden k&ouml;nnen.
        </p>

        <p>
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
          unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
          Datenerfassung auf dieser Website
        </p>

        <p>
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
        </p>

        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
          &#8222;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
          Datenschutzerkl&auml;rung entnehmen.
        </p>

        <p>Wie erfassen wir Ihre Daten?</p>

        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben. Andere Daten werden automatisch oder nach
          Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme
          erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser,
          Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
          Daten erfolgt automatisch, sobald Sie diese Website betreten.
        </p>

        <p>Wof&uuml;r nutzen wir Ihre Daten?</p>

        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
          Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>

        <p>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten? </p>

        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
          Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
          Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
          widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
          Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
        </p>

        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
          sich jederzeit an uns wenden. Analyse-Tools und Tools von
          Drittanbietern Beim Besuch dieser Website kann Ihr Surf-Verhalten
          statistisch ausgewertet werden. Das geschieht vor allem mit
          sogenannten Analyseprogrammen.
        </p>

        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerkl&auml;rung.
        </p>

        <p>2. Hosting</p>

        <p>
          Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
          Amazon.com, Inc.
        </p>

        <p>
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei
          kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und
          Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
          Websitezugriffe und sonstige Daten, die &uuml;ber eine Website
          generiert werden, handeln.
        </p>

        <p>
          Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung
          gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6
          Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
          &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
          Cookies oder den Zugriff auf Informationen im Endger&auml;t des
          Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
          Einwilligung ist jederzeit widerrufbar.
        </p>

        <p>
          Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten,
          wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist
          und unsere Weisungen in Bezug auf diese Daten befolgen.
        </p>

        <p>Wir setzen folgende(n) Hoster ein: Amazon.com, Inc. </p>

        <p>Auftragsverarbeitung </p>

        <p>
          Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
          Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es
          sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </p>

        <p>
          3. Allgemeine Hinweise und Pflichtinformationen Datenschutz Die
          Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
          Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerkl&auml;rung.
        </p>

        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
          Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
          wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
          Zweck das geschieht.
        </p>

        <p>
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.
          B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen
          kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch
          Dritte ist nicht m&ouml;glich.
        </p>

        <p>
          Hinweis zur verantwortlichen Stelle Die verantwortliche Stelle
          f&uuml;r die Datenverarbeitung auf dieser Website ist:
        </p>

        <p>Osteria No 1 Betriebs GmbH Vertreten durch Matteo Lamonaca</p>

        <p>Telefon: +49 (30) 5779 2999 E-Mail: osteriauno.eu(at)gmail.com</p>

        <p>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
          Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>

        <p>
          Speicherdauer Soweit innerhalb dieser Datenschutzerkl&auml;rung keine
          speziellere Speicherdauer genannt wurde, verbleiben Ihre
          personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
          Datenverarbeitung entf&auml;llt.
        </p>

        <p>
          Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
          Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
          gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
          Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten
          haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
          letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser
          Gr&uuml;nde. Allgemeine Hinweise zu den Rechtsgrundlagen der
          Datenverarbeitung auf dieser Website Sofern Sie in die
          Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre
          personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
          bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach
          Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
          ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
          personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
          au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern
          Sie in die Speicherung von Cookies oder in den Zugriff auf
          Informationen in Ihr Endger&auml;t (z. B. via Device-Fingerprinting)
          eingewilligt haben, erfolgt die Datenverarbeitung zus&auml;tzlich auf
          Grundlage von &sect; 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
          widerrufbar. Sind Ihre Daten zur Vertragserf&uuml;llung oder zur
          Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich,
          verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b
          DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur
          Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind auf
          Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann
          ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs.
          1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall
          einschl&auml;gigen Rechtsgrundlagen wird in den folgenden
          Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert. Widerruf
          Ihrer Einwilligung zur Datenverarbeitung Viele
          Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen
          Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte
          Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der
          bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
          unber&uuml;hrt. Widerspruchsrecht gegen die Datenerhebung in
          besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
        </p>

        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
          SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
          IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
          F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING.
        </p>

        <p>
          DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
          EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
          MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
          SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
          DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
          VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
          VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>

        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>

        <p>
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde Im
          Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen
          ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in
          dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
          Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es
          zu. Das Beschwerderecht besteht unbeschadet anderweitiger
          verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe. Recht auf
          Daten&uuml;bertragbarkeit Sie haben das Recht, Daten, die wir auf
          Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags
          automatisiert verarbeiten, an sich oder an einen Dritten in einem
          g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen.
          Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen
          Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
          machbar ist.
        </p>

        <p>
          Auskunft, L&ouml;schung und Berichtigung Sie haben im Rahmen der
          geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
          unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen
          Daten, deren Herkunft und Empf&auml;nger und den Zweck der
          Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
        </p>

        <p>
          Recht auf Einschr&auml;nkung der Verarbeitung Sie haben das Recht, die
          Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen. Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden. Das
          Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden
          F&auml;llen: &bull; Wenn Sie die Richtigkeit Ihrer bei uns
          gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir
          in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
          der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen. &bull; Wenn
          die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig
          geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die
          Einschr&auml;nkung der Datenverarbeitung verlangen. &bull; Wenn wir
          Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch
          zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
          Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
          L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. &bull; Wenn Sie einen
          Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
          Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen
          &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie die
          Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben,
          d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen
          &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung,
          Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum
          Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen
          Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen
          Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats
          verarbeitet werden. 4. Soziale Medien Social-Media-Elemente mit
          Shariff Auf dieser Website werden Elemente von sozialen Medien
          verwendet (z. B. Facebook, Twitter, Instagram, Pinterest, XING,
          LinkedIn, Tumblr).
        </p>

        <p>
          Die Social-Media-Elemente k&ouml;nnen Sie in der Regel anhand der
          jeweiligen Social-Media-Logos erkennen. Um den Datenschutz auf dieser
          Website zu gew&auml;hrleisten, verwenden wir diese Elemente nur
          zusammen mit der sogenannten &#8222;Shariff&ldquo;-L&ouml;sung. Diese
          Anwendung verhindert, dass die auf dieser Website integrierten
          Social-Media-Elemente Ihre personenbezogenen Daten schon beim ersten
          Betreten der Seite an den jeweiligen Anbieter &uuml;bertragen.
        </p>

        <p>
          Erst wenn Sie das jeweilige Social-Media-Element durch Anklicken der
          zugeh&ouml;rigen Schaltfl&auml;che aktivieren, wird eine direkte
          Verbindung zum Server des Anbieters hergestellt (Einwilligung). Sobald
          Sie das Social- Media-Element aktivieren, erh&auml;lt der jeweilige
          Anbieter die Information, dass Sie mit Ihrer IP-Adresse diese Website
          besucht haben. Wenn Sie gleichzeitig in Ihrem jeweiligen
          Social-Media-Account (z. B. Facebook) eingeloggt sind, kann der
          jeweilige Anbieter den Besuch dieser Website Ihrem Benutzerkonto
          zuordnen.
        </p>

        <p>
          Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art.
          6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG dar. Diese
          Einwilligung k&ouml;nnen Sie jederzeit mit Wirkung f&uuml;r die
          Zukunft widerrufen.
        </p>

        <p>
          Der Einsatz des Dienstes erfolgt, um die gesetzlich vorgeschriebenen
          Einwilligungen f&uuml;r den Einsatz bestimmter Technologien
          einzuholen. Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. c
          DSGVO. Instagram Auf dieser Website sind Funktionen des Dienstes
          Instagram eingebunden. Diese Funktionen werden angeboten durch die
          Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal
          Harbour, Dublin 2, Irland.
        </p>

        <p>
          Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung
          zwischen Ihrem Endger&auml;t und dem Instagram-Server hergestellt.
          Instagram erh&auml;lt dadurch Informationen &uuml;ber den Besuch
          dieser Website durch Sie.
        </p>

        <p>
          Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie
          durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit
          Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
          dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
          dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
          &uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalten.
        </p>

        <p>
          Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
          Einsatz des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a
          DSGVO und &sect; 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
          Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung des
          Dienstes auf Grundlage unseres berechtigten Interesses an einer
          m&ouml;glichst umfassenden Sichtbarkeit in den Sozialen Medien.
        </p>

        <p>
          Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten
          auf unserer Website erfasst und an Facebook bzw. Instagram
          weitergeleitet werden, sind wir und die Meta Platforms Ireland
          Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
          gemeinsam f&uuml;r diese Datenverarbeitung verantwortlich (Art. 26
          DSGVO). Die gemeinsame Verantwortlichkeit beschr&auml;nkt sich dabei
          ausschlie&szlig;lich auf die Erfassung der Daten und deren Weitergabe
          an Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende
          Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der
          gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
          Verpflichtungen wurden in einer Vereinbarung &uuml;ber gemeinsame
          Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie
          unter: https://www.facebook.com/legal/controller_addendum. Laut dieser
          Vereinbarung sind wir f&uuml;r die Erteilung der
          Datenschutzinformationen beim Einsatz des Facebook- bzw.
          Instagram-Tools und f&uuml;r die datenschutzrechtlich sichere
          Implementierung des Tools auf unserer Website verantwortlich. F&uuml;r
          die Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook
          verantwortlich. Betroffenenrechte (z. B. Auskunftsersuchen)
          hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten
          k&ouml;nnen Sie direkt bei Facebook geltend machen. Wenn Sie die
          Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
          an Facebook weiterzuleiten.
        </p>

        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:
          https://www.facebook.com/legal/EU_data_transfer_addendum,
          https://help.instagram.com/519522125107875 und
          https://de-de.facebook.com/help/566994660333381.
        </p>

        <p>
          Weitere Informationen hierzu finden Sie in der
          Datenschutzerkl&auml;rung von Instagram:
          https://instagram.com/about/legal/privacy/. 5. Plugins und Tools
        </p>

        <p>
          YouTube Diese Website bindet Videos der Website YouTube ein. Betreiber
          der Website ist die Google Ireland Limited (&#8222;Google&ldquo;),
          Gordon House, Barrow Street, Dublin 4, Irland.
        </p>

        <p>
          Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
          eingebunden ist, wird eine Verbindung zu den Servern von YouTube
          hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer
          Seiten Sie besucht haben.
        </p>

        <p>
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen
          Sie YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
          zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
          YouTube- Account ausloggen.
        </p>

        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
          &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
          Cookies oder den Zugriff auf Informationen im Endger&auml;t des
          Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
          Einwilligung ist jederzeit widerrufbar.
        </p>

        <p>
          Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerkl&auml;rung von YouTube unter:
          https://policies.google.com/privacy?hl=de. Google Fonts (lokales
          Hosting) Diese Seite nutzt zur einheitlichen Darstellung von
          Schriftarten so genannte Google Fonts, die von Google bereitgestellt
          werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu
          Servern von Google findet dabei nicht statt.
        </p>

        <p>
          Weitere Informationen zu Google Fonts finden Sie unter
          https://developers.google.com/fonts/faq und in der
          Datenschutzerkl&auml;rung von Google:
          https://policies.google.com/privacy?hl=de. Font Awesome (lokales
          Hosting) Diese Seite nutzt zur einheitlichen Darstellung von
          Schriftarten Font Awesome. Font Awesome ist lokal installiert. Eine
          Verbindung zu Servern von Fonticons, Inc. findet dabei nicht statt.
        </p>

        <p>
          Weitere Informationen zu Google Fonts finden Sie unter Weitere
          Informationen zu Font Awesome finden Sie in der
          Datenschutzerkl&auml;rung f&uuml;r Font Awesome unter:
          https://fontawesome.com/privacy.
        </p>

        <p>Quelle: https://www.e-recht24.de</p>
      </IonContent>
    </IonPage>
  );
};

export default Privacy;
