import { FC } from "react";
import { IonContent, IonPage } from "@ionic/react";
import Toolbar from "../components/Toolbar";
import "./Location.css";

const Location: FC = () => {
  return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-text-center">
        <div className="map-container">
          <iframe
            title="google-maps-location"
            className="map-iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.4128737996825!2d13.383262400000001!3d52.4897655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85025dd6d86eb%3A0xa63f67ad657eb2e4!2sOsteria%20N%C2%B01!5e0!3m2!1sen!2sde!4v1681478849004!5m2!1sen!2sde"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Location;
