import { FC } from "react";
import {
  IonButtons,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonToolbar,
} from "@ionic/react";
import "./Toolbar.css";

const Toolbar: FC = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonImg
          id="osteria-logo-text"
          src="/assets/logo/white-logo-text.png"
          alt="Osteria N.1 Logo Text"
        />
      </IonToolbar>
    </IonHeader>
  );
};

export default Toolbar;
