import { FC } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonText,
} from "@ionic/react";

const ServiceCard: FC<{ icons: any; title: string; description: string }> = ({
  icons,
  title,
  description,
}) => {
  const { iosIcon, mdIcon } = icons;

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <IonIcon ios={iosIcon} md={mdIcon} />
        </IonCardTitle>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonText>
          <p>{description}</p>
        </IonText>
      </IonCardContent>
    </IonCard>
  );
};

export default ServiceCard;
