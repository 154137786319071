import { FC, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonPage,
  IonText,
  IonToast,
} from "@ionic/react";
import Toolbar from "../components/Toolbar";
import "./Partners.css";
import { useTranslation } from "react-i18next";
import { copyOutline } from "ionicons/icons";

const Partners: FC = () => {
  const { t } = useTranslation("Partners");

  const [isToastOpen, setIsToastOpen] = useState(false);

  const openExternalLink = (url: string | URL | undefined) => {
    window.open(url, "_blank");
  };

  const copyVoucherCode = () => {
    const voucherCode = "OSTERIA20";
    navigator.clipboard.writeText(voucherCode).then(
      () => {
        console.log("Voucher code copied to clipboard");
        setIsToastOpen(true);
      },
      (err) => {
        console.error("Could not copy voucher code: ", err);
      }
    );
  };

  return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-text-center ion-padding-horizontal">
        <IonCard className="partner-card" color="secondary">
          <img
            onClick={() =>
              openExternalLink("https://www.embroiderystoreletizia.com/de-de")
            }
            alt="Embroidery Store Letizia Webpage Preview"
            src="/assets/images/Set_Viaggio_Neonato_Little_Traveller.png"
          />
          <IonCardHeader>
            <IonCardTitle>Embroidery Store Letizia</IonCardTitle>
            <IonCardSubtitle onClick={copyVoucherCode}>
              <a
                href={"https://embroiderystoreletizia.com/de-de"}
                target="_blank"
              >
                embroiderystoreletizia.com
              </a>
              <p>
                {t("embroideryStoreLetizia.subtitle", "Voucher Code")}:{" "}
                <IonText color="tertiary" className="voucher-code">
                  <b>OSTERIA20</b>
                </IonText>
                <IonIcon
                  icon={copyOutline}
                  color="tertiary"
                  className="copy-icon"
                />
              </p>
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonText>
              <p>
                <b>20%</b>{" "}
                {t(
                  "embroideryStoreLetizia.description",
                  "Discount on Made in Italy clothes and accessories for your kids"
                )}
              </p>
            </IonText>
            <IonButton
              color="tertiary"
              fill="outline"
              className="ion-margin"
              onClick={() =>
                openExternalLink("https://www.embroiderystoreletizia.com/de-de")
              }
            >
              {t("embroideryStoreLetizia.go", "go to shop")}
            </IonButton>
          </IonCardContent>
        </IonCard>

        <IonCard className="partner-card">
          <img
            onClick={() => openExternalLink("https://www.apijourney.com")}
            alt="Api Journey Webpage Preview"
            src="/assets/images/aj-logo.png"
          />
          <IonCardHeader>
            <IonCardTitle>Api Journey</IonCardTitle>
            <IonCardSubtitle onClick={copyVoucherCode}>
              <a href={"https://apijourney.com"} target="_blank">
                apijourney.com
              </a>
              <p>
                {t("apijourney.subtitle", "Voucher Code")}:{" "}
                <IonText color="tertiary" className="voucher-code">
                  <b>OSTERIA20</b>
                </IonText>
                <IonIcon
                  icon={copyOutline}
                  color="tertiary"
                  className="copy-icon"
                />
              </p>
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonText>
              <p>
                <b>20%</b>{" "}
                {t(
                  "apijourney.description",
                  "Discount on AI generated Canvas with NFT certificate included"
                )}
              </p>
            </IonText>
            <IonButton
              fill="outline"
              className="ion-margin"
              onClick={() => openExternalLink("https://www.apijourney.com")}
            >
              {t("apijourney.go", "go to shop")}
            </IonButton>
          </IonCardContent>
        </IonCard>
        <IonToast
          isOpen={isToastOpen}
          onDidDismiss={() => setIsToastOpen(false)}
          message={String(
            t(
              "voucherCode.afterCopyMessage",
              "Voucher Code OSTERIA20 copied to clipboard"
            )
          )}
          duration={4000}
        ></IonToast>
      </IonContent>
    </IonPage>
  );
};

export default Partners;
