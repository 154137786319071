import { FC } from "react";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import Toolbar from "../components/Toolbar";
import {
  calendarOutline,
  callOutline,
  locationOutline,
  mailOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";

const Contact: FC = () => {
  const { t } = useTranslation("Contact");

  const handleMapsLink = (e: any) => {
    e.preventDefault();
    const mapsAppUrl =
      "https://www.google.com/maps/search/?api=1&query=Osteria%20N.1%2C+Kreuzbergstr.+71%2C+Berlin%2C+Germany";
    window.open(mapsAppUrl, "_system", "location=yes");
  };
  const emailSubject = t("email.subject", "Information Request");
  const emailTemplate = t(
    "email.template",
    "Dear Osteria Uno team,\n\nI am interested in learning more about your restaurant and would like to request some additional information.\n\nThank you,\n[Your Name]"
  );

  return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-text-center">
        <IonItem button onClick={handleMapsLink} className="ion-padding">
          <IonIcon icon={locationOutline} slot="start" />
          <IonText>Osteria N.1, Kreuzbergstr. 71, Berlin, Germany</IonText>
        </IonItem>

        <IonItem button href="tel:+493057792999" className="ion-padding">
          <IonIcon icon={callOutline} slot="start" />
          <IonLabel>+49 30 577 92 999</IonLabel>
        </IonItem>

        <IonItem
          className="ion-padding"
          button
          href={`mailto:info@osteria-uno.eu?subject=${encodeURIComponent(
            emailSubject
          )}&body=${encodeURIComponent(emailTemplate)}`}
        >
          <IonIcon icon={mailOutline} slot="start" />
          <IonLabel>info@osteria-uno.eu</IonLabel>
        </IonItem>

        <IonItem className="ion-padding">
          <IonIcon icon={calendarOutline} slot="start" />
          <IonText>
            {t("openingHours", "Opening Hours: Everyday from 12:00 to 01:00")}
          </IonText>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default Contact;
