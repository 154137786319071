import {
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  callOutline,
  callSharp,
  heartOutline,
  heartSharp,
  homeOutline,
  homeSharp,
  locationOutline,
  locationSharp,
  ticketOutline,
  ticketSharp,
} from "ionicons/icons";
import "./Menu.css";
import { FC } from "react";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Menu: FC = () => {
  const { t } = useTranslation("Menu");
  const location = useLocation();

  const appPages: AppPage[] = [
    {
      title: t("home", "Home"),
      url: "/",
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
    {
      title: t("contact", "Contact"),
      url: "/Contact",
      iosIcon: callOutline,
      mdIcon: callSharp,
    },
    {
      title: t("location", "Location"),
      url: "/Location",
      iosIcon: locationOutline,
      mdIcon: locationSharp,
    },
    {
      title: t("partnersDiscount", "Partners Discount"),
      url: "/Partners",
      iosIcon: ticketOutline,
      mdIcon: ticketSharp,
    },
    {
      title: t("about", "About"),
      url: "/About",
      iosIcon: heartOutline,
      mdIcon: heartSharp,
    },
  ];

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonImg
            id="osteria-logo"
            src="/assets/logo/white-logo-image.png"
            alt="Osteria N.1 Logo"
          />
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    aria-hidden="true"
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>

      <Footer />
    </IonMenu>
  );
};

export default Menu;
