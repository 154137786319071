import { FC } from "react";
import { IonContent, IonPage, IonProgressBar, IonText } from "@ionic/react";

const Loading: FC = () => {
  return (
    <IonPage>
      <IonContent>
        <IonProgressBar type="indeterminate" color="tertiary"></IonProgressBar>
      </IonContent>
    </IonPage>
  );
};

export default Loading;
