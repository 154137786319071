import { FC } from "react";
import { IonContent, IonPage } from "@ionic/react";
import Toolbar from "../components/Toolbar";

const Imprint: FC = () => {
  return (
    <IonPage>
      <Toolbar />
      <IonContent>
        <p>
          Verantwortlich f&uuml;r den Inhalt dieser Webseite: Osteria No 1
          Betriebs GmbH Vertreten durch: Matteo Lamonaca Kreuzbergstr. 1 10965
          Berlin Tel: + 49 (0) 30 / 5779 2999
        </p>

        <p>
          {" "}
          E-Mail: info(at)osteria-uno.eu Internet: www.osteris-uno.eu
          Umsatzsteuer- Identifikationsnummer: DE315772366
        </p>

        <p>Finanzamt Berlin Kreuzberg Haftung </p>

        <p>
          1. Inhalt des Onlineangebot Der Autor &uuml;bernimmt keinerlei
          Gew&auml;hr f&uuml;r die Aktualit&auml;t, Korrektheit,
          Vollst&auml;ndigkeit oder Qualit&auml;t der bereitgestellten
          Informationen. Haftungsanspr&uuml;che gegen den Autor, die sich auf
          Sch&auml;den materieller oder ideeller Art beziehen, welche durch die
          Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch
          die Nutzung fehlerhafter und unvollst&auml;ndiger Informationen
          verursacht wurden sind grunds&auml;tzlich ausgeschlossen, sofern
          seitens des Autors kein nachweislich vors&auml;tzliches oder grob
          fahrl&auml;ssiges Verschulden vorliegt. Alle Angebote sind
          freibleibend und unverbindlich. Der Autor beh&auml;lt es sich
          ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu
          l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder
          endg&uuml;ltig einzustellen{" "}
        </p>

        <p>
          2. Verweise und Links Bei direkten oder indirekten Verweisen auf
          fremde Internetseiten (&quot;Links&quot;), die au&szlig;erhalb des
          Verantwortungsbereiches des Autors liegen, haftet dieser nur dann,
          wenn er von den Inhalten Kenntnis hat und es ihm technisch
          m&ouml;glich und zumutbar w&auml;re, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern. Der Autor erkl&auml;rt hiermit
          ausdr&uuml;cklich, dass zum Zeitpunkt der Linksetzung die
          entsprechenden verlinkten Seiten frei von illegalen Inhalten waren.
          Der Autor erkl&auml;rt weiterhin, dass er keinerlei Einfluss auf die
          aktuelle und zuk&uuml;nftige Gestaltung und auf die Inhalte der
          gelinkten/verkn&uuml;pften Seiten hat. Deshalb distanziert er sich
          hiermit ausdr&uuml;cklich von allen Inhalten aller gelinkten
          /verkn&uuml;pften Seiten, die nach der Linksetzung ver&auml;ndert
          wurden. Diese Feststellung gilt f&uuml;r alle innerhalb des eigenen
          Internetangebotes gesetzten Links und Verweise sowie f&uuml;r
          Fremdeintr&auml;ge in vom Autor eingerichteten G&auml;steb&uuml;chern,
          Diskussionsforen und Mailinglisten. F&uuml;r illegale, fehlerhafte
          oder unvollst&auml;ndige Inhalte und insbesondere f&uuml;r
          Sch&auml;den, die aus der Nutzung oder Nichtnutzung solcherart
          dargebotener Informationen entstehen, haftet allein der Anbieter der
          Seite, auf welche verwiesen wurde, nicht derjenige, der &uuml;ber
          Links auf die jeweilige Ver&ouml;ffentlichung lediglich verweist.{" "}
        </p>

        <p>
          3. Urheberrecht Der Autor ist bestrebt, in allen Publikationen die
          Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen
          und Texte zu beachten, von ihm selbst erstellte Grafiken,
          Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie
          Grafiken, Tondokumente, Videosequenzen und Texte zur&uuml;ckzugreifen.
          Das Copyright f&uuml;r ver&ouml;ffentlichte, vom Autor selbst
          erstellte Objekte bleibt allein beim Autor der Seiten. Eine
          Vervielf&auml;ltigung oder Verwendung solcher Grafiken, Tondokumente,
          Videosequenzen und Texte in anderen elektronischen oder gedruckten
          Publikationen ist ohne ausdr&uuml;ckliche Zustimmung des Autors nicht
          gestattet.{" "}
        </p>

        <p>
          4. Rechtswirksamkeit dieses Haftungsausschlusses Dieser
          Haftungsausschluss ist als Teil des Internetangebotes zu betrachten,
          von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder
          einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,
          nicht mehr oder nicht vollst&auml;ndig entsprechen sollten, bleiben
          die &uuml;brigen Teile des Dokumentes in ihrem Inhalt und ihrer
          G&uuml;ltigkeit davon unber&uuml;hrt
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Imprint;
